import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Layout from "../components/layout/Layout";
import SEO from "../components/seo";

interface Props {}

const TkaneEtiketyPage = (props: Props) => {
  return (
    <Layout>
      <SEO title="Tkané etikety" />
      <h1>Tkané etikety</h1>
      <div className="flex justify-center">
        <StaticImage
          src="../images/tkane-etikety/ukazky-1.png"
          alt="Tkané etikety"
          className="mb-10"
        />
      </div>
      <p className="mb-4 text-justify">
        Vizitkou výrobce oděvů bývají tkané etikety, které se často přišívají na
        viditelná místa oděvů, například do vnějších švů, na kapsy, pásky, apod.
        Jsou ideálním produktem pro prezentaci značky. Samozřejmě i tkaná
        etiketa může mít informační charakter, může obsahovat údaje o složení,
        ošetřování, atd. Tkané etikety mají výbornou trvanlivost a odolnost vůči
        seprání a oděru. Vyrábějí se v libovolných rozměrech. Polotovarem je
        vytkání stuhy z polyesterových nití. Při tkaní použitím více barev nití
        útku vzniká obrazový motiv etikety. Takto vyrobená stuha se následně
        rozřeže na jednotlivé etikety. Výrobní zařízení nám umožňuje utkat stuhy
        od 6 do 200mm (šířka etikety). Rozřezání stuhy na jednotlivé etikety
        (délka etikety) je od 15mm teoreticky do nekonečna. Pokud se však
        etiketa následně zažehluje, jsou možnosti zažehlení technicky omezeny a
        dokážeme zažehlit etiketu jen do určité délky. Tyto informace Vám rádi
        zdělíme při dotazu na konkrétní zadání. Barevnost etiket je omezená
        škálou dostupných přízí. Nemůžeme tedy nabídnout přesné barevné odstíny
        podle Vašich požadavků, musíme se přizpůsobit vyráběným přízím. Okraje
        tkaných etiket jsou opatřeny proti třepení termořezem (zatavením) s
        možností rozličných zažehlení a úprav laserem. Asi jedinou nevýhodou
        této technologie je nemožnost vyrobit etikety v malém množství, v
        desítkách či stovkách kusů.
      </p>
      <p>
        Možnosti aplikace – etikety, taháčky na zip, stuhy, poutka a podobně.
      </p>
      <p className="mb-4">Na všechny materiály máme certifikát OEKO-TEX.</p>
      <div className="flex justify-center">
        <StaticImage
          src="../images/tkane-etikety/ukazky-2.png"
          alt="Tkané etikety"
          className="mb-10"
        />
      </div>
      <h2 className="mb-4">Velikostní značení</h2>
      <p className="mb-4">
        Pro označování velikostí Vám můžeme nabídnout malé tkané etikety rozměru
        13x20mm ( po přeložení ), v bílé i černé variantě. Balení 100ks za cenu
        32 Kč+DPH.
      </p>
      <p>Skladem máme tyto velikosti:</p>{" "}
      <p>XXXS, XXS, XS, S, M, L, XL, XXL, XXXL, 4XL, 5XL, 6XL</p>{" "}
      <p>
        34, 35, 36, 38, 40, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64, 66,
        68
      </p>{" "}
      <p>
        56, 62, 68, 74, 80, 86, 92, 98, 104, 110, 116, 122, 128, 134, 140, 146
        152, 158, 164, 170, 176 164, 176, 182, 194
      </p>{" "}
      <p className="mb-4">UNI</p>{" "}
      <p className="mb-4">
        Tyto etikety jsou s možností okamžitého dodání. Jakékoli jiné varianty (
        velikostní i barevné ) můžeme vyrobit v režimu standardní zakázky.
      </p>
      <div className="flex justify-center mb-4">
        <StaticImage
          src="../images/tkane-etikety/ukazky-3.png"
          alt="Tkané etikety"
          className="mb-10"
        />
      </div>
      <div className="justify-center flex my-5">
        <table>
          <tr>
            <td className="font-bold pr-10">Minimální množství</td>
            <td>dle konkrétních parametrů zadané etikety
(pohybuje se mezi 1.000 až 5.000 kusů)</td>
          </tr>
          <tr>
            <td className="font-bold pr-10">Materiál</td>
            <td>polyester</td>
          </tr>
          <tr>
            <td className="font-bold pr-10">Barvy tkaní</td>
            <td>max. 7 barev včetně barvy podkladu</td>
          </tr>
          <tr>
            <td className="font-bold pr-10">Tkaní</td>
            <td>jemně přetkaný taft</td>
          </tr>
          <tr>
            <td className="font-bold pr-10">Rozměry etiket</td>
            <td>
              šířka 10 - 100mm
              <br />
              výška 17 - 180mm
            </td>
          </tr>
          <tr>
            <td className="font-bold pr-10">Úpravy</td>
            <td>
              termořez (neparatelné okraje)
              <br />
              obšívání okrajů
              <br />
              ořez laserem (např kruhová nášivka)
            </td>
          </tr>
          <tr>
            <td className="font-bold pr-10">Barevné mutace</td>
            <td>po 500 nebo více kusech</td>
          </tr>
          <tr>
            <td className="font-bold pr-10">Proměnlivé údaje</td>
            <td>
              po 500 nebo více kusech ( např. jednoduché označení velikosti)
            </td>
          </tr>
          <tr>
            <td className="font-bold pr-10">Dodací lhůta</td>
            <td>2 - 4 týdny od potvrzení objednávky</td>
          </tr>
        </table>
      </div>
      <div className="justify-center flex gap-3 mb-10">
        <StaticImage
          src="../images/tkane-etikety/spartak.png"
          alt="Tkané etikety"
        />
        <StaticImage
          src="../images/tkane-etikety/etka-bila.png"
          alt="Tkané etikety"
          imgStyle={{ objectFit: "contain" }}
        />
      </div>
      <h2 className="mb-4 text-center">Druhy zažehlení</h2>
      <div className="flex justify-center mb-5">
        <StaticImage
          src="../images/tkane-etikety/druhy-zazehleni.png"
          alt="Tkané etikety"
          className="mb-10"
        />
      </div>
      <p>
        Poptávky a jakékoli dotazy či připomínky ohledně tkaných etiket
        zasílejte na:{" "}
        <a className="font-bold" href="mailto:tkane@etka.cz">
          tkane@etka.cz
        </a>
      </p>
    </Layout>
  );
};

export default TkaneEtiketyPage;
